/**
 * PassPanda | app main styles
 */

.app-logo {
    width: 64px;
    height: 64px;
}

.password-result {
    padding: 24px;
}

.password-result-text {
    width: 50vw;

    overflow-wrap: break-word;
}

.generator-options {
    max-width: 540px;
}
