/**
 * PassPanda | Bootstrap customization
 */

@import "./../node_modules/bootstrap/scss/functions";

$color-mode-type: media-query;

$primary: #6d29e9;

@import "./../node_modules/bootstrap/scss/variables";
@import "./../node_modules/bootstrap/scss/variables-dark";

@import "./../node_modules/bootstrap/scss/maps";
@import "./../node_modules/bootstrap/scss/mixins";
@import "./../node_modules/bootstrap/scss/utilities";

@import "./../node_modules/bootstrap/scss/root";
@import "./../node_modules/bootstrap/scss/reboot";
@import "./../node_modules/bootstrap/scss/type";
@import "./../node_modules/bootstrap/scss/containers";
@import "./../node_modules/bootstrap/scss/grid";
@import "./../node_modules/bootstrap/scss/forms";
@import "./../node_modules/bootstrap/scss/buttons";
@import "./../node_modules/bootstrap/scss/nav";
@import "./../node_modules/bootstrap/scss/badge";
@import "./../node_modules/bootstrap/scss/helpers";
@import "./../node_modules/bootstrap/scss/utilities/api";
